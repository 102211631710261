import Error from "@/components/error/Error";
import { NextPage } from "next";
import Head from "next/head";

const Custom404: NextPage = props => (
    <>
        <Head>
            <title>ORTEX - Not found</title>
        </Head>
        <Error statusCode={404} title="Page not found" description="The page you are looking for doesn't exist." />
    </>
);

export default Custom404;
