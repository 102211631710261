import styles from "./Error.module.scss";
import { FC } from "react";
import Link from "next/link";
import { useRecoilValue } from "recoil";
import { greyDarkerState } from "@/lib/store";
import Color from "@/lib/Color";
import { Button, Typography } from "@mui/material";

interface PropsTypes {
    statusCode: number;
    title: string;
    description: string;
}

const Error: FC<PropsTypes> = ({ statusCode, title, description }) => {
    const greyDarker = useRecoilValue(greyDarkerState);
    const whiteDarker = new Color(greyDarker).brighten(0.59).get() as string;

    return (
        <div className={styles.Error} style={{ color: greyDarker }}>
            <h2>{statusCode}</h2>
            <h3>{title}</h3>
            <Typography variant="body1" sx={{ mt: 2 }}>
                {description}
            </Typography>
            <Link href="/" passHref>
                <Button variant="outlined">Go Home</Button>
            </Link>
        </div>
    );
};

export default Error;
